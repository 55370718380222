import React from "react";
import {Helmet} from "react-helmet";
import Layout from "../components/layout";
import ContactDiv from "../components/contactUs";


const ErrorPage = () => {
    return(
        <Layout>
            <Helmet>
                <title>Stanford Sonoma - 404 Wrong turn</title>
            </Helmet>
            <div className={'SharedEl__container'}>
                <div className={'Error__marginTop Error__wrapperYO'}>
                    <h1 className={'Error__centerText'}>Error 404</h1>
                    <p className={'Error__centerText'}>
                        You got lost!
                    </p>
                    <p className={'Error__centerText'}>
                        Choose a link from the Navigation to get back on track!
                    </p>
                </div>
                <ContactDiv />
            </div>
        </Layout>
    );
};

export default ErrorPage;
